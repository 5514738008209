module.exports = [{
      plugin: require('/Users/kensakurai/Programing/Bitbucket/portfolio/node_modules/gatsby-plugin-guess-js/gatsby-browser.js'),
      options: {"plugins":[],"GAViewID":"161464162","minimumThreshold":0.01,"period":{"startDate":"2016-12-31T15:00:00.000Z","endDate":"2019-08-11T11:37:40.967Z"}},
    },{
      plugin: require('/Users/kensakurai/Programing/Bitbucket/portfolio/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/kensakurai/Programing/Bitbucket/portfolio/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#c62828"},
    },{
      plugin: require('/Users/kensakurai/Programing/Bitbucket/portfolio/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/kensakurai/Programing/Bitbucket/portfolio/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/kensakurai/Programing/Bitbucket/portfolio/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/kensakurai/Programing/Bitbucket/portfolio/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MDJFD5S"},
    },{
      plugin: require('/Users/kensakurai/Programing/Bitbucket/portfolio/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
